import React from 'react'

import { Link } from 'gatsby'

import * as styles from '../styles/timeline.module.css'
import {Col, Card}  from 'react-bootstrap'

export default function TimelineItem({date, title, link, image}) {
    
    return (
        <Col xs={12} md={4} className={styles.timeline_item}>
        <Card className={styles.tutorialCard}>
            {image &&   <Card.Img variant="top" src={image} />}         
            <Card.Body>

            
                        <div className={styles.item}>
                            {date}
                            <Link to={link} >
                                <h2>{title} </h2>
                            </Link>                            
                        </div>
                    
            </Card.Body>
        </Card>
        </Col>

        
    )
}

