import React from "react"
import Layout from "../components/Layout"
import { graphql, navigate  } from 'gatsby'

import Seo from '../components/SEO'
import {Carousel, Row, Col}  from 'react-bootstrap'

import TimelineItem from '../components/Timeline_Item'
import TutorialIcon from '../components/TutorialIcon'


import * as styles from '../styles/index.module.css'


export default function Home({data}) {

  const latest = data.allMarkdownRemark.nodes

  return (
    <Layout>  
      <Seo 
      title="Online Web Tutorials"
      description="Kafle.io is an one-stop place where you can learn many frameworks and programming languages such as Laravel/PHP, ASP.NET Core, NET, C#, JQuery, AJAX"
      keywords="ASP.NET, C#, .NET 5, .NET 6, Laravel, PHP, Joomla, Wordpress"
      />   

      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/header1.jpg"
            alt="header1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/header2.jpg"
            alt="header2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/images/header3.jpg"
            alt="header3"
          />
        </Carousel.Item>
      </Carousel>
      <Row className={styles.tutorial}>
          <Col sm={12}>
            <div className={styles.title}>Tutorials</div>
          </Col>
            <TutorialIcon
              title=".NET/C#"
              link="/tutorials/asp-dot-net/"
              img="/images/icons/aspnet.jpg"
            />
            <TutorialIcon
              title="PHP 8 (Web Development)"
              link="/tutorials/php"
              img="/images/icons/php8.png"
            />
            <TutorialIcon
              title="Laravel"
              link="/tutorials/laravel"
              img="/images/icons/laravel.jpg"
            />
            <TutorialIcon
              title="nodejs"
              link="/tutorials/nodejs"
              img="/images/icons/nodejs.png"
            />
            <TutorialIcon
              title="Joomla (Web Development)"
              link="/tutorials/joomla"
              img="/images/icons/joomla.png"
            />
            <TutorialIcon
              title="Joomla (Web Development with e-commerce)"
              link="/tutorials/wordpress"
              img="/images/icons/wordpress.png"
            />
            <TutorialIcon
              title="Server/Network"
              link="/tutorials/server-network"
              img="/images/icons/server.png"
            />

            <TutorialIcon
              title="Tips & Tricks"
              link="/tutorials/tips-and-tricks"
              img="/images/icons/tips.png"
            />
      </Row>
      <Row className={styles.cardList}>
            {latest.map(item => (
                <TimelineItem  key={item.id}
                    date={item.frontmatter.modified}
                    title={item.frontmatter.title}
                    description={item.frontmatter.description}
                    link = {"/" + item.frontmatter.slug}
                    image = {"/" + item.frontmatter.image}

                  />
            ))}            
              
      </Row>
      <Row className="indexOlderTutorial">
            <button className="btn btn-outline-primary" onClick={()=>{navigate("/tutorials")}}>All Tutorials</button>
      </Row>
    </Layout>
  )
}


export const query = graphql`
query latestBlog {
  allMarkdownRemark(
    limit: 30
    sort: {fields: frontmatter___published, order: DESC}
    filter: {frontmatter: {featured: {eq: 1}}}
  ) {
    nodes {
      frontmatter {
        slug
        title
        description
        image
        modified(formatString: "MMMM DD, YYYY")
      }
      id
    }
  }
}
`
