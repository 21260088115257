import React from 'react'
import { Link } from 'gatsby'
import {Col}  from 'react-bootstrap'

import * as styles from '../styles/tutorialicon.module.css'

export default function TutorialIcon({title, img, link}) {
    return (
        <Col sm={3} className={styles.indexIcon}>
            <Link to={link} >
                <img src={img} alt="icon" />
                <p className="text-muted">
                    {title}
                </p>          
            </Link>
        </Col>
    )
}
